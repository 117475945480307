// ==========================================================================
// Buttons
// ==========================================================================

@use "sass:math";

.btn {
    display: inline-block;

    border-radius: 0px;
    background: $iron;
    transition: all linear 100ms;

    text-transform: uppercase;
    &:hover,
    &:focus,
    &:active {
        transition: all linear 100ms;
        background-color: $primary;

        color: $white;
    }
}

/* == Variante deux couleurs et icône */
.btn--two-colors-icon {
    display: table;
    padding: 0 0 0 rem(50);

    background: $transparent no-repeat left 10px center / rem(30) rem(30);

    color: $primary;
    font-family: $font-demibold;
    font-size: rem(12);
    &:hover,
    &:focus,
    &:active {
        background-color: $transparent;

        color: $black;
        text-decoration: none;
    }
}

.btn--two-colors-icon__text {
    display: table-cell;
    vertical-align: middle;
    padding: 0.2em 1em 0.1em;
    height: 100%;

    background: $iron;

    line-height: 1.2;
    text-align: left;
    white-space: initial;
    word-break: break-word;
    .btn--two-colors-icon:hover &,
    .btn--two-colors-icon:focus &,
    .btn--two-colors-icon:active & {
        background: $primary;
        color: $white;
    }
    .reverse & {
        text-align: right;
    }
}

.btn--gettheoffer {
    background-image: url("../../../img/svg/icon_anim.svg");
}

.btn--faq {
    background-image: url("../../../img/svg/icon_FAQ.svg");
}

.btn--contacts {
    background-image: url("../../../img/svg/icon_contacts.svg");
}

.btn--document {
    background-image: url("../../../img/svg/icon_doc.svg");
}

/* == Variante bouton large */
.btn-large {
    font-size: rem(18);
    font-weight: bold;
}

/* == Variante bouton avec icône */
.btn-icon {
    padding: rem(5) rem(2) rem(5) rem(35);

    background-repeat: no-repeat;
    background-position: left rem(10) center;
    background-size: rem(24) rem(28);
}

.btn-icon--subscribe {
    width: 100%;

    background-image: url("../../../img/svg/icon_souscrire.svg");

    white-space: initial;
    word-break: break-word;
    &:hover,
    &:focus,
    &:active {
        background-image: url("../../../img/svg/icon_souscrire_white.svg");
    }
}

@include media-breakpoint-up(lg) {
    .btn-icon--subscribe {
        width: auto;
        padding: rem(7) rem(2) rem(7) rem(35);

        font-size: rem(14);
    }
}

@include media-breakpoint-up(xl) {
    .btn-icon--subscribe {
        font-size: rem(18);
    }
}

/* Bouton scroll top */
.btn--scroll-top {
    position: absolute;
    top: 0;
    right: rem(20);
    margin-top: -(math.div($grid-gutter-width, 2));
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;

    border-radius: rem(20);
    img {
        width: rem(40);
        height: rem(40);
    }
    &:hover,
    &:focus,
    &:active {
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
    }
}

@include media-breakpoint-up(md) {
    .btn--scroll-top {
        right: 0;
    }
}
