/**
 *
 * VIEWS
 * Styles dédiés à une page / rubrique.
 *
 * Regroupe principalement des propriétés de positionnement(margin, padding)
 * et de dimensions (width, height). Si des styles doivent être présents sur plusieurs
 * pages, plutôt les placer dans `modules` ou `elements`
 *
 */

// ==========================================================================
// Choose your country (première page)
// ==========================================================================

@import 'choose-country';

// ==========================================================================
// Home
// ==========================================================================

@import 'home';