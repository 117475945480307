// ==========================================================================
// Layout
// ==========================================================================

@use "sass:math";

html, body { height: 100%; }

body {
  display: flex;
  flex-direction: column;
}

.overlay {
    display: none;
    &.active {
        display: block;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: 2;

        background: rgba($black, 0.1);
        cursor: pointer;
    }
}

.main {
  flex: 1 0 auto;
}

.column-container {
  width: 100%; height: 100%;
  padding: 0 math.div($grid-gutter-width, 2)
}

@include media-breakpoint-up(md) {
  .column-container {
    height: auto;
    padding: 0;
  }
}

.footer {
  flex-shrink: 0;
}

/* margins for layout */

.mt-leftcol {
  margin-top: rem(40);
}

.mt-rightcol {
  margin-top: rem(20);
}

@include media-breakpoint-up(sm) {
  .mt-leftcol,
  .mt-rightcol,
  .mt-body{
    margin-top: rem(40);
  }
}

@include media-breakpoint-up(lg) {

  .mt-body,
  .mt-rightcol {
    margin-top: rem(40);
  }
}
