// ==========================================================================
// Functions
// ==========================================================================

/*----------  Conversion en rem  ----------*/
// Usage : `font-size: rem(14)`

@use "sass:math";

@function rem($value) {
    @return math.div($value, $font-size-base-px) * 1rem;
}
