// ==========================================================================
// Compteur colonne de droite
// ==========================================================================

.subscription-timer {
    word-break: break-word;
}

.subscription-timer__label {
    margin: 0;
    padding: rem(5) rem(5) rem(5) rem(40);

    background: $iron (url("../../../img/svg/icon_timing.svg") no-repeat left rem(10) center / 17px auto);

    color: $primary;
    font-size: rem(11);
    font-family: $font-demibold;
    text-transform: uppercase;
}

.subscription-timer__counter {
    padding: rem(5) 0;

    background: $primary;

    color: $white;
    .reverse & {
        flex-direction: row-reverse;
    }
}

.subscription-timer__elem {
    padding: 0 rem(5);

    font-size: rem(12);
}

.subscription-timer__elem--days {
    font-weight: bold;
    text-align: right;
}

.subscription-timer__elem--hours {
    border-left: 1px solid $white;
    border-right: 1px solid $white;

    text-align: center;
}

.subscription-timer__elem--hours {
    text-align: center;
}
