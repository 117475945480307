// ==========================================================================
// Menu
// ==========================================================================

@use "sass:math";

.navbar {
  height: 100%;
}

.btn-toggle-menu {
    left: 5%;
    top: rem(20);
    position:relative;
    z-index: 3;
  width: 30px; height: 30px;

  background: url("../../../img/svg/Menu_On.svg") no-repeat center / 30px;
  border: none;
  &.active {
    background-image: url("../../../img/svg/Menu_Off.svg");
  }
}

.nav {
  position: absolute;
  top: 0;
  //margin-left: -($grid-gutter-width / 2);

  background: rgba(255, 255, 255, 0.95);


  // sr only
  width: 1px; height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);

  white-space: nowrap;
  &.active {
    width: 100%; height: initial;
    clip: initial;
    overflow: auto;
    margin-top: rem(30);
    z-index: 2;

    white-space: normal;

    border: 1px solid $primary;
  }
    .reverse & {
        margin-left: 0;
        margin-right: -(math.div($grid-gutter-width, 2));
    }
}

@include media-breakpoint-up(md) {
  .nav,
  .nav.active {
    position: static;
    width: initial; height: initial;
    clip: initial;
    top: initial;
    overflow: initial;
    margin-top: 0;

    background: none;

      border-top: 1px solid $iron;
      border-bottom: 1px solid $iron;
      border-left: none;
      border-right: none;
  }
}
