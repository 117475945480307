// ==========================================================================
// Changement de langue
// ==========================================================================

%pl-language-text-style {

    text-transform: uppercase;
    font-family: $font-demibold;
    font-size: rem(12);
    word-break: break-all;
}


.language__button { //Pour version desktop, voir navigation.scss
    display: inline-block;
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0 rem(0) rem(10) 0;
    color: white;

    @extend %pl-language-text-style;
    .reverse & {
        padding-right: rem(20);
    }
}

.language__list {
    position: absolute;
    top: 100%;
    right: 0;
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 50vw;
    z-index: 3;
    background: rgba($white, 0.9);
    border: 1px solid $primary;

    text-align: center;
    &.sr-only {
        width: 1px;
    }
    .reverse & {
        right: initial; left: 0;
    }
}

@include media-breakpoint-only(sm) {
    .language__list {
        min-width: rem(150);
    }
}

.language__link {
    display: block;
    padding: rem(10) rem(3);
    color: $black;
    @extend %pl-language-text-style;
    &:hover,
    &:focus,
    &:active {
        background: $primary;

        color: $white;
        text-decoration: none;
    }
    &.active {
        color: $primary;
        text-decoration: underline;
        &:hover,
        &:focus,
        &:active {
            background: $primary;

            color: $white;
            text-decoration: none;
        }
    }
}

@include media-breakpoint-up(sm) {
    .language-container {
        position: relative;
    }

    .language__list {
        right: rem(30);
        left: initial;
        width: initial;
    }

    .language__link {
        padding: rem(10) rem(20);
    }
}

.language-container {
    text-align: right;
    .reverse & {
        text-align: left;
    }
}
