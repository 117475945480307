// ==========================================================================
// Breadcrumbs
// ==========================================================================

/*doc
---
title: Fil d'Ariane
name: breadcrumb
category: Modules
---

```html_example
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">Library</li>
  </ol>
</nav>
```

*/