// ==========================================================================
// Footer
// ==========================================================================

.footer {
  position: relative;
  margin-top: rem(40);
  padding: rem(19) 0;

  background: $iron;

  font-family: $font-demibold;
}


.footer__legals {
  color: $nevada;

  font-family: $font-demibold;
  &:hover,
  &:focus,
  &:active {
    color: $nevada;
  }
}

.footer__rights {
  display: block;
  margin: 0;

  color: $nevada;
    .reverse & {
        text-align: center;
    }
}

.footer-logo {
    width: 100%;
}

.footer-img-logo {
  max-width: 100%;
}

@include media-breakpoint-up(lg) {
    .footer__rights {
        display: inline-block;
        &:before {
            content: "|";
            margin: 0 rem(19) 0 rem(15);
        }
    }

    .footer-logo {
        width: 15%;
    }
}
