// ==========================================================================
// Forms
// ==========================================================================

/*doc
---
title: Formulaires
name: forms
category: Base CSS
---

```html_example
<form>
	<!-- Text -->
	<div class="form-group">
		<label for="input-text">Text:</label>
		<input type="text" id="input-text" class="form-control">
		<small id="input-text" class="form-text text-muted">Help text</small>
	</div>

	<!-- Readonly -->
	<div class="form-group">
		<label for="input-readonly">Readonly:</label>
		<input type="text" class="form-control" placeholder="Readonly input here…" readonly>
	</div>

	<!-- Email -->
	<div class="form-group">
		<label for="input-email">Email:</label>
		<input type="email" id="input-email" class="form-control" placeholder="name@example.com">
	</div>

	<!-- Tel -->
	<div class="form-group">
		<label for="input-tel">Tel:</label>
		<input type="tel" id="input-tel" class="form-control" pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$">
	</div>

	<!-- Select -->
	<div class="form-group">
		<label for="select">Select:</label>
		<select id="select" class="form-control">
			<option>1</option>
			<option>2</option>
			<option>3</option>
		</select>
	</div>

	<!-- Checkbox -->
	<div class="form-group">
		<div class="form-check">
		  <input type="checkbox" class="form-check-input" id="input-checkbox">
		  <label class="form-check-label" for="input-checkbox">
		    Checkbox
		  </label>
		</div>
	</div>

	<!-- Radio -->
	<div class="form-group">
		<div class="form-check">
		  <input type="radio" class="form-check-input" id="input-radio">
		  <label class="form-check-label" for="input-radio">
		    Radio
		  </label>
		</div>
	</div>

	<!-- File -->
	<div class="form-group">
		<label for="input-file">File:</label>
		<input type="file" id="input-file" class="form-control-file">
	</div>

	<!-- Textarea -->
	<div class="form-group">
		<label for="textarea">Textarea:</label>
		<textarea id="textarea" class="form-control" rows="3"></textarea>
	</div>

	<button type="submit" class="btn btn-primary">Valider</button>
</form>
```

*/

.form-label {
    display: block;
    padding-left: rem(10);

    font-size: 13px;
}

/* Forms spécifiques */
.form--language {
    position: relative;
    z-index: 1;
}

.language-field-container {
    position: relative;
    z-index: 1;
}

//@include media-breakpoint-up(md) {
//    .language-field-container {
//        width: 300px;
//    }
//}
