/**
 *
 * ELEMENTS
 * Styles dédiés aux éléments spécifiques
 *
 * Certains éléments nécessitent un code spécifique qui ne peut être ré-utilisable
 * au travers du projet (à l'inverse des modules). Il s'agit de styles fortement
 * dépendants du contexte et du contenu de l'élément.
 *
 * Exemple : slider home, payment form, weather widget...
 *
 */

// ==========================================================================
// Le texte du premier écran
// ==========================================================================

@import 'welcoming-text';

// ==========================================================================
// Icône Boost 2018 premier écran
// ==========================================================================

@import 'picto-boost';

// ==========================================================================
// Compteur colonne de droite
// ==========================================================================

@import 'subscription-timer';

// ==========================================================================
// Prix de souscrition colonne de droite
// ==========================================================================

@import 'subscription-price';

// ==========================================================================
// Liste des points clés page groupe
// ==========================================================================

@import 'list-key';

// ==========================================================================
// Bloc Plus d'information
// ==========================================================================

@import 'more-information';

// ==========================================================================
// Liste pdf à télécharger
// ==========================================================================

@import 'list-pdf';

// ==========================================================================
// Liste des contacts
// ==========================================================================

@import 'list-contacts';

// ==========================================================================
// Video
// ==========================================================================

@import 'video';

// ==========================================================================
// Switch language
// ==========================================================================

@import 'switch-language';

// ==========================================================================
// Tarte au citron
// ==========================================================================

@import 'tarteaucitron';

// ==========================================================================
// Logo EssilorLuxottica
// ==========================================================================

@import "logo";

