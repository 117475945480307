// ==========================================================================
// Select2 customisation
// ==========================================================================

.select2-container {
  box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.3);
}

.select2-container--default {
  .select2-selection--single {
    height: rem(34);

    border-radius: 0px;
    .select2-selection__arrow{
      height: rem(30);
      b {
        border-top-color: $black;
      }
    }
    .select2-selection__rendered {
      line-height: 34px;
    }
  }
}