// ==========================================================================
// Liste des contacts
// ==========================================================================

.list-contacts {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.list-contacts__item {
    margin-bottom: rem(20);

    line-height: 1;
    .bloc &:before {
        display: none;
    }
}

.list-contacts__card {
    display: block;
    padding: rem(10) rem(20) rem(10) rem(45);

    background: $white url("../../../img/svg/contact.svg") no-repeat left 15px top 10px / 16px auto;

    color: $primary;
    font-family: $font-demibold;
    p {
        margin: 0;
        padding: 0;
    }
    a {
        color: $black;
        font-family: $font-family-sans-serif;
        font-size: rem(13);
        text-decoration: none;
    }
    .reverse & {
        padding: rem(10) rem(45) rem(10) rem(20);

        background-position: right 15px top 10px;
        a {
            display: block;
            padding-top: rem(5);

            text-align: right;
        }
    }
}

@include media-breakpoint-up(lg) {
    .list-contacts {
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: rem(15);
    }

    .list-contacts__item {
        flex-grow: 1;
        &:nth-child(even) {
            .reverse & {
                padding-left: rem(0);
                padding-right: rem(10);
            }
        }
    }
}
