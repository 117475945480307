// ==========================================================================
// Custom
// ==========================================================================

$font-size-base-px: 16; // = 1rem

/* Color */
$black: #000000;
$nevada: #6D6E71;
$grey: #929292;
$silver-chalice: #B1B0B0;
$silver: #C7C7C7;
$iron: #E6E7E8;
$white: #FFF;
$transparent: transparent;

$danube: #79AFD9;
/* Fonts */
$font-regular: 'Avenir Next regular', Arial;
$font-demibold: 'Avenir Next demi bold', Arial;
$font-medium: 'Avenir Next medium', Arial;

// ==========================================================================
// Variables Bootstrap
// ==========================================================================

/* Colors 2018 */
$primary: #8e388d;
$secondary: #6D6E71;

$mine-shaft: #2B2B2B;
$gray: #929292;
$gallery: #F0F0F0;

$goldenrod: #F28921;

/* Color 2019  */
$primary: #005192;
$congress-blue: #004181;
$spindle: #B6D1E6;
$boticelli: #D3E1ED;


/* Fonts */
$font-family-sans-serif: 'Avenir Next', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: 'Avenir Next';

  // ==========================================================================
// Fonts-face
// ==========================================================================

@font-face {
  font-family: 'Avenir Next';
  src: url('../../../fonts/AvenirNext-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../../fonts/AvenirNext-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../../../fonts/AvenirNext-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next demi bold';
  src: url('../../../fonts/AvenirNext-DemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next medium';
  src: url('../../../fonts/AvenirNext-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1280px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px
);

.font-size-10 {
    font-size: 10px;
}
