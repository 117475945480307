// ==========================================================================
// Le texte du premier écran
// ==========================================================================

.welcoming-text {
  p {
    color: black;
    font-family: $font-demibold;
    font-size: rem(16);
    line-height: 1.2;
  }
    .reverse & {
        left: initial; right: 0;
        h1 {
            background: none;
        }
    }
}

.login-title {
    line-height: 1;
    font-size: rem(40);
}

@include media-breakpoint-up(md) {
  .welcoming-text {
    p {
      font-size: rem(25);
    }
  }
}

.welcoming-text__slogan {
  font-family: "Avenir Next";
  text-transform: none;
  color: $primary;
  padding: 0;
  width: 100%;
  background: none;
  font-size: rem(30);
  span {
    font-size: 0.6em;
  }
}

.welcoming-text__text {
    color: $primary;
}

.welcoming-text__text-2 {
    color: $secondary;
}

@include media-breakpoint-up(sm) {
  .welcoming-text__slogan {
    font-size: rem(30);
  }
}

@include media-breakpoint-up(md) {
  .welcoming-text__slogan {
    width: 100%;
    font-size: rem(45);
  }
}

@include media-breakpoint-up(lg) {
    .login-title {
        font-size: rem(54);
        color: $primary;
    }
}

//@media screen and (min-width:768px) and (max-height:700px) {
//    .welcoming-text__slogan {
//        font-size: rem(25);
//        span {
//            font-size: 0.8em;
//        }
//    }
//    .welcoming-text {
//        p {
//            font-size: rem(20);
//        }
//    }
//}
