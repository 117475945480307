// ==========================================================================
// Bloc Plus d'informations
// ==========================================================================

.article--more-informations {
  margin-top: rem(40);
  .h1 {
    width: initial;
    margin-bottom: rem(20);

    background: none;

    font-family: $font-family-sans-serif;
    font-size: rem(26);
    text-align: center;
    word-break: break-word;
    span {
    }
  }
  p {
    flex: 1 1 0;
    padding: rem(80) rem(15) rem(15);

    background: no-repeat top center / rem(94) auto;

    color: $primary;
    font-family: $font-demibold;
    font-size: rem(15);
    text-align: center;
  }
}

.more-information__text {
    p:first-child {
        background-image: url("../../../img/info_boost.png");
        padding-left: 0;
    }
    p:nth-child(2) {
        background-image: url("../../../img/svg/Info_Groupe.svg");
        border-left: 1px solid $primary;
        padding-right: 0;
    }
    .reverse & {
        p:nth-child(2) {
            border-left: none;
            border-right: 1px solid $primary;
        }
    }
}

