// ==========================================================================
// Sub Header
// ==========================================================================

.sub-header {
    height: rem(80);
 background-image: url("../../../img/bandeau_4.jpg");
 background-size: cover;
}

@include media-breakpoint-up(sm) {
    .sub-header {
        &:before {
            height: 100px;
        }
        &:after {
            width: 340px; height: 100px;
        }
    }
}

@include media-breakpoint-up(md) {

}

.sub-header__title {
    margin: 0;

    font-size: rem(40);
    line-height: 1;
    word-break: break-all;
    small {
        display: block;
        font-size: rem(18);
    }
}

@media screen and (max-width: 576px) {
    .sub-header__title {
        font-size: rem(28);
        small {
            font-size: rem(15);
        }
    }
}

@include media-breakpoint-up(sm) {
    .sub-header__title {
        font-size: rem(44);
        small {
            font-size: rem(22);
        }
    }
}

.sub-header__slogan {
    position: relative;
    z-index: 1;

    font-size: rem(16);
}

@include media-breakpoint-up(md) {
    .sub-header__slogan {
        font-size: rem(22);
    }
}

