// ==========================================================================
// Icône Boost 2018 premier écran
// ==========================================================================

.picto-boost {
  position: absolute;
  right: rem(30); bottom: rem(40);
  width: 167px;
  margin: 0;

  img {
    width: 100%;
  }
    .reverse & {
        right: initial; left: rem(30);
    }
}
