// ==========================================================================
// Header
// ==========================================================================

.header {
    position: relative;
    z-index: 3;

    background: $white;

    &.header--border {
        border-top: 20px solid $primary;
    }
}

@include media-breakpoint-up(lg) {
    .header {
        //position: static;
        &.header--border {
            border-top: none;
        }
    }
}

.header__container {
    @include make-container();
}

@include media-breakpoint-up(lg) {
    .header__container {
        @include make-container();
        @include make-container-max-widths();
    }
}

.header__logo {
  height: rem(20);
  margin: 0;
  img {
    height: rem(20);
  }
    .reverse & {
        text-align: right;
    }
}

@include media-breakpoint-up(sm) {
    .header__logo {
        height: rem(25);
        img {
            height: rem(25);
        }
    }
}
