// ==========================================================================
// Nav primary
// ==========================================================================

.nav-primary {
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

@include media-breakpoint-up(md) {
    .nav-primary {
        justify-content: space-evenly;
        .reverse & {
            justify-content: end;
        }
    }
}

.nav-item {
    flex-grow: 1;

    text-align: center;
    & + & {
        .nav-primary__link {
            width: 100%;
        }
    }
}

@include media-breakpoint-up(md) {
    .nav-item {
        flex: auto;
        & + & {
            .nav-primary__link {
                background-image: linear-gradient($gray, $gray);
                background-repeat: no-repeat;
                background-size: 1px 90%;
                background-position: left top;
                .reverse & {
                    background-position: right top;
                }
            }
        }
        &:last-child {
            background-image: linear-gradient($gray, $gray);
            background-repeat: no-repeat;
            background-size: 1px 90%;
            background-position: left top;
            .reverse & {
                background-position: right top;
            }
        }
    }
}

.nav-primary__link {
    display: block;
    padding: 0 0 rem(4);
    font-size: rem(14);
    text-transform: uppercase;
    color: $black;
    span {
        display: inline-block;
        padding: rem(14) 0 rem(3);
    }
    &:hover,
    &:focus,
    &:active,
    &.active {
        text-decoration: none;
        > span {
            background: linear-gradient(to top, $primary 2px, $transparent 0);
        }
    }
}

@include media-breakpoint-up(md) {
    .nav-primary__link {
        display: inline-block;
        padding: rem(0) rem(10);

        font-size: rem(11);
        img {
            display: inline-block;
            width: 16px;
            height: 17px;
            vertical-align: top;
        }
        span {
            padding: 0;
        }
    }
}

@include media-breakpoint-up(lg) {
    .nav-primary__link {
        .nav-item:first-child & {
            padding-left: 0;
            .reverse & {
                padding-left: rem(20);
            }
            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
                > span {
                    background: none;
                }
            }
        }
        span {
            padding: 0 0 rem(3);
        }
    }
}

.language__button {
    &:hover,
    &:focus,
    &:active {
        color: $primary;
    }
}

@include media-breakpoint-up(md) { // Pour version mobile voir switch-language.scss
    .language__button {
        padding: 0 rem(0) 0 rem(20);
        cursor: pointer;
    }
}

.button__landing {
    display: inline-block;

    padding: rem(0) rem(0) rem(0) rem(10);
    img {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 17px;
        z-index: 1;
    }
    .reverse & {
        padding: rem(0) rem(15) rem(0) rem(0) ;
    }
}

.menu-language {
    margin-top: rem(-10);
}

@include media-breakpoint-up(md) {
    .menu-language {
        margin-top: 0;
    }
}

// ==========================================================================
// Nav secondary
// ==========================================================================
.nav-secondary {
    margin: 0; padding: 0;
    width: 100%;
    list-style-type: none;
}

.nav-secondary__item {
    & + & {
        margin-top: rem(10);
    }
}

.nav-secondary__link {
    width: 100%;
    height: rem(32);
    .reverse & {
        text-align: right;
    }
}

.nav-secondary__text {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
}
