// ==========================================================================
// Typographie
// ==========================================================================

/*doc
---
title: Typographie
name: typography
category: Base CSS
---

```html_example
<h1>Titre de niveau 1</h1>
<h2>Titre de niveau 2</h2>
<h3>Titre de niveau 3</h3>
<h4>Titre de niveau 4</h4>
<h5>Titre de niveau 5</h5>
<h6>Titre de niveau 6</h6>
```

*/

.reverse {
    text-align: right;
}

.slogan {
  margin: 0;

  color: $white;
  font-size: rem(22);
  text-transform: uppercase;
  font-family: $font-demibold;
  line-height: 1;
  span {
    display: block;

    text-transform: none;
    color: $silver-chalice;
    font-family: $font-family-sans-serif;
  }
    .reverse & {
        text-align: right;
        p,
        span {
            text-align: right;
        }
    }
}

h1, .h1 {
  margin-bottom: rem(25);
  padding: rem(15) 0 rem(20);

  color: $primary;
  font-family: $font-demibold;
  font-size: rem(22);
  text-transform: uppercase;
  background: linear-gradient(to right, rgba(0, 51, 92, 0.4) 60px, transparent 0) no-repeat left bottom/100% 3px;

  small {
    display: block;
    text-transform: none;
  }
  &.text-transform-none {
    text-transform: none;
  }
    .reverse & {
        text-align: right;
        background-image: linear-gradient(to left, rgba(00, 51, 92, 0.4) 60px, $transparent 0);
    }
}

@include media-breakpoint-down(xs) {
    h1, .h1 {
        margin-top: rem(20);
    }
}

@include media-breakpoint-up(md) {
  h1, .h1 {
    width: 290px;
  }
}

h2, .h2 {
  font-size: rem(14);
  font-weight: bold;
  text-transform: uppercase;
    .reverse & {
        text-align: right;
    }
}

p {
    .reverse & {
        text-align: right;
    }
}

.additional-info {
    color: $primary;
    font-size: rem(13);
    text-align: center;
}

/***** Blocs de contenu *****/
// Voir les styles sur la partie bloc
