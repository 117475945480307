// ==========================================================================
// Liste pdf à télécharger
// ==========================================================================

.list-pdf {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0; padding: 0;
}

.list-pdf__item {
  margin-bottom: rem(20);
  a {
    display: block;
    padding: rem(10) rem(20) rem(10) rem(45);

    background: $gallery url("../../../img/svg/icon_PDF.svg") no-repeat left 15px center / rem(16) auto;

    color: $primary;
    font-family: $font-demibold;
      .reverse & {
          padding: rem(10) rem(45) rem(10) rem(20);

          background-position: right 15px center;

          text-align: right;
      }
  }

}

@include media-breakpoint-up(lg) {
  .list-pdf {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .list-pdf__item {
    flex: 0 1 50%;
    &:nth-child(odd) {
      padding-right: rem(10);
    }
    &:nth-child(even) {
      padding-left: rem(10);
        .reverse & {
            padding-left: rem(0);
            padding-right: rem(10);
        }
    }
  }
}
