// ==========================================================================
// CMS
// ==========================================================================

/**
 * Tous les styles de ce module ciblent les contenus contribués par l'utilisateur (contenus et blocs).
 *
 * Ce qui implique qu'on ne peut pas cibler les éléments par des classes, mais via un
 * sélecteur d'éléments (.wysiwyg h2, .wysiwyg a...).
 *
 * Pour les contenus, il faut englober le contenu par la classe `.wysiwyg`.
 */

/*doc
---
title: WYSIWYG
name: wysiwyg
category: Modules
---

```html_example
<div class="wysiwyg">
	<h1>Titre de niveau 1</h1>
	<p>Lorem ipsum dolor <strong>sit amet</strong>, consectetur <em>adipisicing elit</em>.</p>
	<ul>
		<li>Lorem ipsum.</li>
		<li>Lorem ipsum.</li>
		<li>Lorem ipsum.</li>
	</ul>
</div>
```

*/

.wysiwyg {
	/* @note : Tous les styles appliqués aux contenus contribués doivent être placés ici */
}

