// ==========================================================================
// Right column
// ==========================================================================

.right-col {
  position: relative;
  z-index: 1;
}

.right-col__img {
  width: 100%;
}