// ==========================================================================
// Accordion
// ==========================================================================

.accordion {
    .card,
    .card-header,
    .card-body {
        background: none;
        border: none;
    }

    .card-header {
        padding: rem(5) rem(0);
    }
}

.accordion-header {
    cursor: pointer;

    background: none;
    border: none;

    color: $primary;
    font-family: $font-demibold;
    font-size: rem(16);
    text-align: left;
    .reverse & {
        display: block;

        text-align: right;
    }
}
