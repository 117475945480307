// ==========================================================================
// Blocs
// ==========================================================================

/***** Initialisation du compteur pour la liste ordonnéee *****/
body {
    counter-reset: counter-list;
}

.bloc,
.block-container.default {
    margin-bottom: rem(15);
    padding: rem(15) rem(25) rem(15);

    background: $iron;
}

.bloc--light-blue,
.block-container.blue {
    background: #414042;

    color: $white !important;
    font-family: $font-demibold;

    p, span {
        color: white !important;
    }

    &.block-richtext ul li::before {
        background: $white;
    }

    .block-title {
        background: $black;
    }
}

.block-title {
    background-color: $primary;
    font-weight: bold;
    text-transform: uppercase;
    color: $white;
    .reverse & {
        text-align: right;
    }
}

.bloc-info {
    padding: 2em 1em 1.8em;

    background: $primary;

    color: $white;
    text-align: right;
    text-transform: uppercase;
    font-family: $font-demibold;
    font-size: rem(13);
    word-break: break-word;
}

.bloc--border-orange {
    background: $primary;
}

@include media-breakpoint-up(sm) {

}

.bloc-info--blue {
    background: $primary;

    color: $white;
    text-transform: none;
}

/***** Style des textes associés  *****/

/* Bloc gris */
.bloc,
.block-richtext {
    h2, .h2 {
        margin-bottom: rem(10);

        color: $primary;
        font-family: $font-demibold;
        font-size: rem(20);
        font-weight: normal;
        text-transform: none;
    }

    h3, .h3 {
        color: $primary;

        font-family: $font-demibold;
        font-size: rem(15);
    }

    p {
        margin-top: rem(10);
        margin-bottom: rem(5);
    }
    a {
        color: inherit;
        text-decoration: underline;
        &:hover,
        &:focus,
        &:active {

        }
    }

    .notice-title {
        color: $primary;

        font-family: $font-demibold;
        text-decoration: underline;
        text-transform: uppercase;
    }
    table {
        margin-bottom: rem(15);

        border: 0;
        thead {
            border-top: 2px solid $primary;
            border-bottom: 2px solid $primary;

            color: $black;
        }
        th {
            padding: rem(10) rem(5) rem(20);

            font-family: $font-demibold;
            font-size: rem(12);
        }
        tbody {
            tr:nth-child(odd) {
                background: $iron;
            }
            td {
                border: 0;
            }
        }
        &.shares-value {
            border-collapse: separate;
        }
    }
    .bloc-info {
        margin: rem(10) 0 0;

        font-size: rem(17);
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            &:before {
                content: "";
                display: inline-block;
                width: rem(6);
                height: rem(6);
                margin-right: rem(5);

                background: $black;
                border-radius: rem(4);
            }
        }
    }
}

@include media-breakpoint-only(xs) {
    .block-richtext {
        table {
            tbody {
                td {
                    font-size: 10px;
                    padding: rem(2);
                }
            }
        }
    }
}


ul {
    &.blue-dots {
        font-size: rem(14);
        li {
            &:before {
                background: $primary;
            }
        }
        .text-highlight {
            color: $primary;
            font-family: $font-demibold;
        }
    }
    &.blue-blocks {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        list-style-type: none;

        word-break: break-all;
        li {
            flex: 1 1 0;
            &:before {
                display: none;
            }
            p {
                background: $primary;
                padding: rem(5);

                color: $white;
                font-family: $font-demibold;
                font-size: rem(14);
            }
        }
    }
}

ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
        &:before {
            counter-increment: counter-list;
            content: counter(counter-list) ") ";

            font-weight: bold;
        }
    }
}

@include media-breakpoint-up(md) {
    .bloc {
        position: relative;
        ul {
            &.blue-dots {
                width: 55%;
            }
        }
        .bloc-info {
            padding: 1rem 1em 1em 3em;
            position: absolute;
            right: rem(25);
            bottom: rem(15);
            width: 40%;
        }
    }
    ul {
        &.blue-blocks {
            flex-direction: row;
        }
    }
    /* Spécifique item de liste blocs bleus */
    .blue-blocks__item {
        & + & {
            padding-left: rem(14);
        }
    }
}

/* Bloc avec en-tête */
.bloc--headed {
    h2 {
        margin: rem(-15) rem(-25) rem(15);
        padding: rem(7) rem(25) rem(4);
        background: $primary;

        color: $white;
    }
}
