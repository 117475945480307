// ==========================================================================
// Corner triangles
// ==========================================================================

.triangle-tl {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 235px;

    background: linear-gradient(to bottom right, rgba($boticelli, 0.88) 50%, $transparent 0) no-repeat left -10% top / 100% 235px;
    .reverse & {
        background: linear-gradient(to bottom left, rgba($boticelli, 0.88) 50%, $transparent 0) no-repeat left -10% top / 100% 235px;
    }
}

.triangle-bl {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(15deg, rgba($primary, 0.88) 50%, $transparent 0) no-repeat left bottom / 100% 85%;
    .reverse & {
        background: linear-gradient(-15deg, rgba($primary, 0.88) 50%, $transparent 0) no-repeat left bottom / 100% 85%;
    }
}

@include media-breakpoint-up(md) {
    .triangle-bl {
        background-size: 100% 80%;
    }

    .triangle-br {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 413px;
        height: 266px;

        background: linear-gradient(to bottom right, $transparent 50%, rgba(255, 255, 255, 0.87) 0) left top;
        .reverse & {
            left: 0; right: initial;
            background: linear-gradient(to bottom left, $transparent 50%, rgba(255, 255, 255, 0.87) 0) left top;
        }
    }
}

