// =========================================================================
// Choose country
// ==========================================================================

//.main--choose-country {
//    position: relative;
//
//    //background: $grey url("../img/Visuel_home.jpg") no-repeat center top / cover;
//    &:after {
//        content: "";
//        display: block;
//        position: absolute;
//        top: 0;
//        right: 0;
//        bottom: 0;
//        width: 447px;
//        height: 178px;
//
//        .reverse & {
//            right: initial;
//            left: 0;
//        }
//    }

.login-title {
    font-size: rem(40);
    color: $primary;
}

.language-title{
    font-size: rem(40);
    color: $primary;
}

.login-widget{
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.3);
}

.select2-container {
    box-shadow: none;

    .select2-selection--single {
        border: solid 1px #d3d3d385;
        height: 50px !important;
    }
    .select2-selection__rendered {
        line-height: 50px !important;
    }
    .select2-selection__arrow {
        height: 50px !important;
    }
}

@include media-breakpoint-up(lg) {
    .login-title {
        line-height: 1;
        font-size: rem(54);
    }

    .language-title{
        font-size: rem(54);
    }
}
