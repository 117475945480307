// ==========================================================================
// Tarte au citron
// ==========================================================================
//Surcharge style tarte au citron

#tarteaucitron {
    font-family: $font-medium!important;
}

#tarteaucitronPersonalize {
    background-color: #d6d9db!important;
    color: $primary!important;
}

.tarteaucitronDeny{
    background-color: $primary!important;
    color: $white!important;
}
#tarteaucitronDisclaimerAlert {
    color: $primary!important;
}
#tarteaucitronRoot #tarteaucitronAlertBig {
    box-shadow: -1px 2px 3px -1px lightgrey!important;
    background: #fff!important;
}

#tarteaucitronCloseAlert {
    color: #fff!important;
    background-color: $primary!important;
}

#tarteaucitronPrivacyUrl{
    color: $primary!important;
}
