/**
 *
 * MODULES
 * Styles dédiés aux composants
 *
 * Regroupe les composants ré-utilisables du projet (pas de dépendance
 * contextuelle, pas de dépendance à leur contenu).
 *
 * Exemple : button, form, slider, accordion...
 *
 */

// ==========================================================================
// Breadcrumbs
// ==========================================================================

@import 'breadcrumbs';

// ==========================================================================
// Buttons
// ==========================================================================

@import 'buttons';

// ==========================================================================
// CMS
// ==========================================================================

@import 'cms';

// ==========================================================================
// Forms
// ==========================================================================

@import 'forms';

// ==========================================================================
// Tables
// ==========================================================================

@import 'tables';

// ==========================================================================
// Type
// ==========================================================================

@import 'type';

// ==========================================================================
// Blocs
// ==========================================================================

@import 'blocs';

// ==========================================================================
// Corner triangles
// ==========================================================================

@import 'corner-triangles';

// ==========================================================================
// Select2
// ==========================================================================

@import 'select2';

// ==========================================================================
// Accordion
// ==========================================================================

@import 'accordion';