// ==========================================================================
// Liste des points clés page groupe
// ==========================================================================

.list-key {
    display: flex;
    flex-direction: column;
    margin: rem(20) 0 0;
    padding: 0;
    list-style-type: none;
}

@include media-breakpoint-up(sm) {
    .list-key {
        flex-direction: row;
    }
}

.list-key__item {
    position: relative;
    padding: rem(90) rem(20) rem(10) rem(10);

    background-repeat: no-repeat;

    font-size: rem(12);
    .text-primary {
        font-weight: bold;
    }
    .text-big-size {
        font-size: rem(18.5);
        line-height: 1;
        letter-spacing: -1px;
    }
    p {
        padding-top: rem(25);

        line-height: rem(25);
    }
    &:after {
        position: absolute;
        left: 0;
        top: rem(100);
        content: "";
        width: rem(1);
        height: rem(84);

        background: $primary;
        border-radius: rem(5);
    }
}

@include media-breakpoint-up(sm) {
    %line-break-pl {
        content: "\A";
        white-space: pre;
    }
    .list-key__item {
        flex: 1 1 0;
        align-self: flex-start;
        .line-break-b {
            &:before {
                @extend %line-break-pl;
            }
        }
        .line-break-a {
            &:after {
                @extend %line-break-pl;
            }
        }
    }
}

.list-key__item--ca {
    background-image: url(../../../img/svg/boost_icon_sales_revenue.svg);
    background-position: top rem(10) center;
    background-size: rem(50) auto;
}

.list-key__item--salaries {
    background-image: url(../../../img/svg/boost_icon_employees.svg);
    background-position: top rem(10) center;
    background-size: rem(50) auto;
}

.list-key__item--patents {
    background-image: url(../../../img/svg/boost_icon_patents.svg);
    background-position: top rem(10) center;
    background-size: rem(50) auto;
}

.list-key__item--brands {
    background-image: url(../../../img/svg/boost_icon_brands.svg);
    background-position: top rem(10) center;
    background-size: rem(50) auto;
}

.list-key__item--stores {
    background-image: url(../../../img/svg/boost_icon_stores.svg);
    background-position: top rem(10) center;
    background-size: rem(50) auto;
}

.article--keys {
    .h1 {
        position: relative;
        margin-bottom: 0;
        padding: 0;

        background: none;

        font-size: rem(27);

        text-transform: none;
        small {
            display: block;

            color: $primary;
            font-family: $font-family-sans-serif;
            font-size: rem(27);
            font-weight: normal;
            text-transform: none;
        }
        .reverse & {
            padding-right: 30px;
        }
    }
}

@include media-breakpoint-up(sm) {
    .article--keys {
        .h1 {
            &:after {
                .reverse & {
                    right: 0;
                    -moz-transform: scaleX(-1);
                    -o-transform: scaleX(-1);
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                    filter: FlipH;
                    -ms-filter: "FlipH";
                }
            }
        }
    }
}
