// ==========================================================================
// Prix de souscription colonne de droite
// ==========================================================================

.subcription-price {
  margin: 0 auto;

  color: $primary;
  font-size: rem(13.5);
  text-align: center;
  text-transform: uppercase;
  word-break: break-all;
    .reverse & {
        text-align: center;
    }
}

.subcription-price__highlight {
  font-size: rem(15);
  font-weight: bold;
}

.subcription-price__price {
  display: block;

  font-size: rem(25);
  font-weight: bold;
  span {
      white-space: nowrap;
  }
}
