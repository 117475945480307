// ==========================================================================
// Helpers
// ==========================================================================

.text-right-ltr {
    text-align: right;
    .reverse & {
        text-align: left;
    }
}

.text-align-rtl-reverse {
    .reverse & {
        text-align: right;
    }
}

// ==========================================================================
// Media Queries (useful for mixins)
// ==========================================================================

$media-queries: (
    xs,
    sm,
    md,
    lg,
    xl,
    xxl
);

// ==========================================================================
// Spacings
// ==========================================================================
/*
 * Useful classes for margins & paddings, taking account media queries.
 * ex: `.m-a-25` (margins of 25px), `.m-x-25` (horiz. margins of 25px), `.p-y-70` (vertic. paddings of 70px),
 * `.m-l-md-25` (margin-left 25px, within md media-query), `.p-r-xs-10` (padding-right 10px, within xs media-query)
*/

//@each $space in $spaces{
$i: 0;
@while $i <= 200 {

    // margins
    .m-a-#{$i} { margin: #{$i}px !important; }
    .m-y-#{$i} { margin-top: #{$i}px !important; margin-bottom: #{$i}px !important; }
    .m-x-#{$i} { margin-left: #{$i}px !important; margin-right: #{$i}px !important; }
    .m-t-#{$i} { margin-top: #{$i}px !important; }
    .m-b-#{$i} { margin-bottom: #{$i}px !important; }
    .m-l-#{$i} { margin-left: #{$i}px !important; }
    .m-r-#{$i} { margin-right: #{$i}px !important; }
    // paddings
    .p-a-#{$i} { padding: #{$i}px !important; }
    .p-y-#{$i} { padding-top: #{$i}px !important; padding-bottom: #{$i}px !important; }
    .p-x-#{$i} { padding-left: #{$i}px !important; padding-right: #{$i}px !important; }
    .p-t-#{$i} { padding-top: #{$i}px !important; }
    .p-b-#{$i} { padding-bottom: #{$i}px !important; }
    .p-l-#{$i} { padding-left: #{$i}px !important; }
    .p-r-#{$i} { padding-right: #{$i}px !important; }

    $i: $i + 5;
}

// ==========================================================================
// Media Queries - Spacings
// ==========================================================================


@each $media-query in $media-queries {

    @include media-breakpoint-up(#{$media-query}) {

        $i: 0;
        @while $i <= 200 {
            // margins
            .m-a-#{$media-query}-#{$i} {
                margin: #{$i}px !important;
            }
            .m-y-#{$media-query}-#{$i} {
                margin-top: #{$i}px !important;
                margin-bottom: #{$i}px !important;
            }
            .m-x-#{$media-query}-#{$i} {
                margin-left: #{$i}px !important;
                margin-right: #{$i}px !important;
            }
            .m-t-#{$media-query}-#{$i} {
                margin-top: #{$i}px !important;
            }
            .m-b-#{$media-query}-#{$i} {
                margin-bottom: #{$i}px !important;
            }
            .m-l-#{$media-query}-#{$i} {
                margin-left: #{$i}px !important;
            }
            .m-r-#{$media-query}-#{$i} {
                margin-right: #{$i}px !important;
            }

            .m-a-#{$media-query}-n#{$i} {
                margin: -#{$i}px !important;
            }
            .m-y-#{$media-query}-n#{$i} {
                margin-top: -#{$i}px !important;
                margin-bottom: -#{$i}px !important;
            }
            .m-x-#{$media-query}-n#{$i} {
                margin-left: -#{$i}px !important;
                margin-right: -#{$i}px !important;
            }
            .m-t-#{$media-query}-n#{$i} {
                margin-top: -#{$i}px !important;
            }
            .m-b-#{$media-query}-n#{$i} {
                margin-bottom: -#{$i}px !important;
            }
            .m-l-#{$media-query}-n#{$i} {
                margin-left: -#{$i}px !important;
            }
            .m-r-#{$media-query}-n#{$i} {
                margin-right: -#{$i}px !important;
            }

            // paddings
            .p-a-#{$media-query}-#{$i} {
                padding: #{$i}px !important;
            }
            .p-y-#{$media-query}-#{$i} {
                padding-top: #{$i}px !important;
                padding-bottom: #{$i}px !important;
            }
            .p-x-#{$media-query}-#{$i} {
                padding-left: #{$i}px !important;
                padding-right: #{$i}px !important;
            }
            .p-t-#{$media-query}-#{$i} {
                padding-top: #{$i}px !important;
            }
            .p-b-#{$media-query}-#{$i} {
                padding-bottom: #{$i}px !important;
            }
            .p-l-#{$media-query}-#{$i} {
                padding-left: #{$i}px !important;
            }
            .p-r-#{$media-query}-#{$i} {
                padding-right: #{$i}px !important;
            }

            .p-a-#{$media-query}-n#{$i} {
                padding: -#{$i}px !important;
            }
            .p-y-#{$media-query}-n#{$i} {
                padding-top: -#{$i}px !important;
                padding-bottom: -#{$i}px !important;
            }
            .p-x-#{$media-query}-n#{$i} {
                padding-left: -#{$i}px !important;
                padding-right: -#{$i}px !important;
            }
            .p-t-#{$media-query}-n#{$i} {
                padding-top: -#{$i}px !important;
            }
            .p-b-#{$media-query}-n#{$i} {
                padding-bottom: -#{$i}px !important;
            }
            .p-l-#{$media-query}-n#{$i} {
                padding-left: -#{$i}px !important;
            }
            .p-r-#{$media-query}-n#{$i} {
                padding-right: -#{$i}px !important;
            }

            $i: $i + 5;
        }
    }
}
