// ==========================================================================
// Module video
// ==========================================================================

.video-js {
    position: relative;
    width: 100%;

    background: $transparent;
}

@include media-breakpoint-up(sm) {
    .video-js {
        height: 346px;
    }
}

.video-container {
    color: $white;
}

.video-js {
    //.vjs-icon-play:before,
    //.vjs-big-play-button .vjs-icon-placeholder:before,
    //.vjs-play-control .vjs-icon-placeholder:before {
    //  display: none;
    //}
    .vjs-big-play-button {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: rem(68);
        height: rem(40);

        background: $primary;
        border: none;
        border-radius: rem(20);

        line-height: rem(40);
    }
    .vjs-icon-play:before {

    }
    .video-js .vjs-big-play-button .vjs-icon-placeholder:before,
    .video-js .vjs-play-control .vjs-icon-placeholder:before {
        color: $white;
    }
    &:hover,
    &:focus {
        .vjs-big-play-button {
            border: none;
            background-color: $transparent;
        }
    }
}
